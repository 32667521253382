"use client";
import React, { useContext, useEffect, useMemo } from "react";

import { AnalyticsBrowser } from "@segment/analytics-next";
import { usePathname } from "next/navigation";
import { useAuth } from "@clerk/nextjs";

const writeKey = process.env["NEXT_PUBLIC_ANALYTICS_WRITE_KEY"]!;

export type EventContextType = {
  pageView: (tags: string[]) => void;
  analytics: AnalyticsBrowser;
};

// @ts-expect-error typescript
export const EventContext = React.createContext<EventContextType>();

export function EventProvider({ children }: { children: React.ReactNode }) {
  const { userId } = useAuth();
  const pathname = usePathname();
  const analytics = useMemo(() => new AnalyticsBrowser(), [writeKey]);

  useEffect(() => {
    analytics
      .load(
        {
          writeKey,
          cdnURL: "https://cseg.cakemembers.com",
        },
        {
          integrations: {
            "Segment.io": {
              apiHost: "aseg.cakemembers.com/v1",
              protocol: "https", // optional
            },
          },
        }
      )
      .catch((e) => {
        console.error(e);
      });
  }, [analytics, writeKey]);

  useEffect(() => {
    if (userId) {
      analytics.identify(userId);
    }
  }, [userId]);

  const pageView = (tags: string[]) => {
    analytics.page({
      path: pathname,
      tags,
    });
    console.log("pageView fired", pathname, tags);
  };

  return (
    <EventContext.Provider value={{ pageView, analytics }}>
      {children}
    </EventContext.Provider>
  );
}

export const useEventTracking = () => useContext(EventContext);
